:root {
  --toastify-color-info: #4f74d1;
  --toastify-color-success: #66cc66;
  --toastify-color-warning: #ffb647;
  --toastify-color-error: #ff6666;
  --toastify-z-index: 10000;
  --toastify-font-family: 'Rubik,Helvetica,Arial,sans-serif';
  --toastify-toast-width: 400px;
}
/** Used to define container behavior: width, position: fixed etc... **/
.Toastify__toast-container {
  padding: 0;
}

/** Used to define the position of the ToastContainer **/
.Toastify__toast-container--top-left {
  top: 7.3em;
  left: 1em;
}
.Toastify__toast-container--top-center {
  top: 7.3em;
  margin-left: -160px;
  left: 50%;
}
.Toastify__toast-container--top-right {
  top: 7.3em;
  right: 1em;
}
.Toastify__toast-container--bottom-left {
  bottom: 1em;
  left: 1em;
}
.Toastify__toast-container--bottom-center {
  bottom: 1em;
  margin-left: -160px;
  left: 50%;
}
.Toastify__toast-container--bottom-right {
  bottom: 1em;
  right: 1em;
}

/** Classes for the displayed toast **/
.Toastify__toast {
  padding: 0;
  min-height: 40px;
  box-shadow: 0px 12px 24px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}
.Toastify__toast-body {
  margin: 0;
  padding: 0;
  align-items: inherit;
}

/** Used to position the icon **/
.Toastify__toast-icon {
  display: none;
  padding: 0;
}

/** Classes for the close button. Better use your own closeButton **/
.Toastify__close-button {
  display: none;
}
