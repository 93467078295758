.DayPicker-Caption {
  display: inline-block;
  font-size: 22px;
  color: #333333;
  font-weight: 600;
}

@media all and (max-height: 670px) {
  .DayPicker-Caption > div {
    font-size: 16px;
  }
}

.DayPicker-NavBar {
  display: inline-block;
}

.DayPicker-Month {
  margin: 0;
}

.DayPicker-Month .DayPicker-Caption > div {
  font-weight: 600;
}

.DayPicker-Week {
  display: flex;
  align-items: center;
  margin: 4px 0;
}

.DayPicker-Day {
  padding: 0 13px;
  width: 36px;
  height: 36px;
  font-size: 16px;
  line-height: 36px;
  flex: 0 0 36px;
  box-sizing: content-box;
  position: relative;
}

@media (max-height: 670px) {
  .DayPicker-Day {
    width: 24px;
    height: 24px;
    flex: 0 0 24px;
    line-height: 24px;
    padding: 0 18px;
  }
}

.DayPicker-Day:hover:not(.DayPicker-Day--selected) {
  background-color: transparent !important;
}

.DayPicker-Day--today {
  color: #4f4f4f;
}

.DayPicker-Day--today::before {
  content: '';
}

.DayPicker-Day--selected {
  background-color: rgba(0, 92, 229, 0.2) !important;
  color: #4f4f4f !important;
  border-radius: 0 !important;
}

.DayPicker-Day--start,
.DayPicker-Day--end {
  background: transparent !important;
  color: #ffffff !important;
}

.DayPicker-Day--start::after,
.DayPicker-Day--end::after {
  content: '';
  display: block;
  top: 0;
  bottom: 0;
  background-color: rgba(0, 92, 229, 0.2);
  position: absolute;
  z-index: -1;
}

.DayPicker-Day--today::before,
.DayPicker-Day--start::before,
.DayPicker-Day--end::before {
  content: '';
  display: block;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  z-index: -1;
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
}

@media (max-height: 670px) {
  .DayPicker-Day--today::before,
  .DayPicker-Day--start::before,
  .DayPicker-Day--end::before {
    width: 24px;
    height: 24px;
  }
}

.DayPicker-Day--today::before {
  background-color: #f7f9fc;
}

.DayPicker-Day--start::before,
.DayPicker-Day--end::before {
  background-color: #4f74d1;
}

.DayPicker-Day--start.DayPicker-Day--end::after {
  background-color: transparent;
}

.DayPicker-Day--start.DayPicker-Day--today::after {
  background-color: transparent;
}

/* ======= Weekdays ======== */

.DayPicker-Weekdays {
  display: block;
}

.DayPicker-WeekdaysRow {
  display: flex;
  justify-content: space-between;
}
