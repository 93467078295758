.tooltype__container {
  height: 100%;
  position: relative;
}
.tooltype__container:hover .tooltype__message {
  opacity: 1;
  transform: translateX(-50%);
}
.tooltype__container:hover .tooltype__arrow {
  opacity: 1;
  transform: translateX(-50%) translateY(-15px);
}

.tooltype__message {
  pointer-events: none;
  position: absolute;
  top: calc(100% + 6px);
  left: 50%;
  transform: translateX(-50%) translateY(20px);
  background: #171a1c;
  padding: 0 8px;
  color: #fff;
  unicode-bidi: plaintext;
  opacity: 0;
  transition-duration: 0.2s;
  transition-delay: 0;
  transition-property: opacity, transform;
  will-change: opacity, transform;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 11;
  font-size: 10px;
  line-height: 20px;
  text-align: center;
}

.tooltype__arrow {
  width: 0px;
  height: 0px;
  border: 7px solid transparent;
  border-bottom: 7px solid #171a1c;
  pointer-events: none;
  position: absolute;
  top: calc(100% + 8px);
  left: 50%;
  transform: translateX(-50%) translateY(5px);
  opacity: 0;
  transition-duration: 0.2s;
  transition-delay: 0;
  transition-property: opacity, transform;
  will-change: opacity, transform;
  z-index: 11;
}

.tooltype__arrow_up {
  bottom: calc(100% + 6px);
  transform: translateX(-50%) translateY(5px);
  border: 7px solid transparent;
  border-top: 7px solid #171a1c;
  top: 9px;
}

.tooltype__message span {
  white-space: nowrap;
}
.tooltype__message.is-nowrap span {
  white-space: normal;
  text-align: center;
}

.tooltype__message_up {
  bottom: calc(100% + 6px);
  transform: translateX(-50%) translateY(20px);
  top: auto;
}
